export default class HTTPErrorHelper extends Error {
  // Type:
  // GENERAL = Error general en el código
  // BACKEND = Error referente al backend
  // TOKEN   = Error referente a tokens
  // LOGOUT  = Error referente a tokens y necesita que se vuelvan a autenticar

  constructor(type = "GENERAL", message) {
    super(message);
    this.message = message;
    this.type = type;
  }
}
