import axios from "axios";
//import { Buffer } from 'buffer' // FOR BASE64 ENCODING / DECODING

import HTTPErrorHelper from "./httpError.helper";
import AuthHelper from "./auth.helper";

class HTTPHelper {
    static instance = null;
    static createInstance() {
        var object = new HTTPHelper();
        return object;
    }

    static getInstance() {
        if (!HTTPHelper.instance) {
            HTTPHelper.instance = HTTPHelper.createInstance();
        }
        return HTTPHelper.instance;
    }

    constructor(){
    }

    async httpTokenPersonal(credentials,isDebug=false){
        try {
            var ENV_AUTHPATH = process.env.API_AUTH_PATH;
            var urlRequest = ENV_AUTHPATH+"/token/personal";

            if(isDebug){
                urlRequest = urlRequest+"?XDEBUG_SESSION_START=phpstorm-xdebug";
            }

            var objRequest = {
                email: credentials.email,
                clave: credentials.clave,
            }
            return await axios.post(urlRequest, objRequest);

        } catch (error) {
            var objError = new HTTPErrorHelper('TOKEN', error);
            return Promise.reject(objError);
        }
    }

    async httpTokenRequest(credentials,isDebug=false){
        try {
            var ENV_AUTHPATH = process.env.API_AUTH_PATH;
            var urlRequest = ENV_AUTHPATH+"/token/request";

            if(isDebug){
                urlRequest = urlRequest+"?XDEBUG_SESSION_START=phpstorm-xdebug";
            }

            var objRequest = {
                email: credentials.email,
                clave: credentials.clave,
            }
            return await axios.post(urlRequest, objRequest);

        } catch (error) {
            var objError = new HTTPErrorHelper('TOKEN', error);
            return Promise.reject(objError);
        }
    }

    async httpTokenRefresh(refreshToken,isDebug=false){
        try {
            var ENV_AUTHPATH = process.env.API_AUTH_PATH;
            var urlRefresh = ENV_AUTHPATH+"/token/refresh";

            if(isDebug){
                urlRefresh = urlRefresh+"?XDEBUG_SESSION_START=phpstorm-xdebug";
            }

            var objRefresh = {
                refreshToken: refreshToken,
            }
            return await axios.post(urlRefresh, objRefresh);

        } catch (error) {
            var objError = new HTTPErrorHelper('TOKEN', error);
            return Promise.reject(objError);
        }
    }

    async httpPublicPOST(url,body,isDebug=false,isBlob=false){
        try {
            // [IMPORTANT]
            // You cannot refresh a Refresh Token if the Refresh Token has expired or otherwise been revoked.
            // You must repeat the authentication flow to obtain a new Refresh Token.

            //var actualPublicAccessToken = store.getters['authStore/getAccessToken'];
            var actualPublicAccessToken = AuthHelper.getAccessToken();
            //console.log(">>> ACTUAL publicAccessToken = "+actualPublicAccessToken);

            //console.log("");
            //console.log("[http.helper] httpPublicPOST =========================");
            //console.log("[http.helper] httpPublicPOST =========================");
            //console.log("[http.helper] httpPublicPOST :: URL: ",url);
            //console.log("[http.helper] httpPublicPOST =========================");

            if(actualPublicAccessToken==null){
                actualPublicAccessToken="";
            }

            if(actualPublicAccessToken==""){
                //console.log("[http.helper] httpPublicPOST :: actualPublicAccessToken = '' ");
                var ENV_AUTHPATH = process.env.API_AUTH_PATH;
                var urlRequest = ENV_AUTHPATH+"/token/personal";

                if(isDebug){
                    urlRequest = urlRequest+"?XDEBUG_SESSION_START=phpstorm-xdebug";
                }

                //var encryptedEmail = Buffer.from(process.env.API_TOKEN_PUBLIC_USER).toString('base64');
                //var encryptedPassword = Buffer.from(process.env.API_TOKEN_PUBLIC_PASSWORD).toString('base64');
                var encryptedEmail = btoa(process.env.API_TOKEN_PUBLIC_USER);
                var encryptedPassword = btoa(process.env.API_TOKEN_PUBLIC_PASSWORD);

                var objRequest = {
                    email: encryptedEmail,
                    clave: encryptedPassword,
                }

                //console.log("[http.helper] httpPublicPOST :: actualPublicAccessToken = '' // urlRequest: ",urlRequest," / objRequest: ",objRequest);

                var result = await axios.post(urlRequest, objRequest);
                //console.log("[http.helper] httpPublicPOST :: actualPublicAccessToken = '' // axios.post: ",result);

                var responseStatus = result.status;
                var responseMessage = result.data.message;
                var responseData = result.data.response;

                //console.log("[http.helper] httpPublicPOST :: actualPublicAccessToken = '' // responseStatus: ",responseStatus," / responseMessage: ",responseMessage,' / responseData: ',responseData);

                switch(responseStatus) {
                    case 200:
                        var newPublicAccessToken = responseData.access_token;
                        //store.dispatch('authStore/setAccessToken',newPublicAccessToken);
                        AuthHelper.setAccessToken(newPublicAccessToken);
                        //console.log(">>> NEW publicAccessToken = "+newPublicAccessToken);
                        //console.log("[http.helper] httpPublicPOST :: actualPublicAccessToken = '' // NEW publicAccessToken: ",newPublicAccessToken);
                        break;

                    default:
                        //console.log("[http.helper] httpPublicPOST :: actualPublicAccessToken = '' // ERROR - RESPONSE: ",responseStatus);
                        var objError = new HTTPErrorHelper('TOKEN', 'Error en Personal Token - '+responseMessage);
                        return Promise.reject(objError);
                }
            }

            //console.log("[http.helper] httpPublicPOST :: API_GATEWAY_PATH 01");

            var httpURL = process.env.API_GATEWAY_PATH;
            if(isDebug){
                httpURL = httpURL+"?XDEBUG_SESSION_START=phpstorm-xdebug";
            }

            var objRequest = {};
            objRequest['url'] = url;
            objRequest['data'] = body;

            const axiosApiInstance = axios.create();

            //console.log("[http.helper] httpPublicPOST :: API_GATEWAY_PATH 02");

            axiosApiInstance.interceptors.request.use(
                config => {
                    config.url = httpURL;
                    config.data = objRequest;

                    //var accessToken = store.getters['authStore/getAccessToken'];
                    var accessToken = AuthHelper.getAccessToken();
                    //console.log(">>> accessToken = "+accessToken);
                    // console.log("[http.helper] httpPublicPOST :: interceptors.request 00 - httpURL: ",httpURL," / objRequest: ",objRequest);
                    //console.log("[http.helper] httpPublicPOST :: interceptors.request 01 - obtenemos 'accessToken'");
                    config.headers['Authorization'] = 'Bearer ' + accessToken;
                    //console.log("[http.helper] httpPublicPOST :: interceptors.request 02 - definimos 'Bearer'");
                    return config;
                },
                error => {
                    var objError = new HTTPErrorHelper('TOKEN', error);
                    return Promise.reject(objError);
                }
            );

            axiosApiInstance.interceptors.response.use((response) => {
                    //console.log("[http.helper] httpPublicPOST :: interceptors.response 01 / obtenemos response: ",response);
                    //console.log("[http.helper] httpPublicPOST ------------------------------------------------------------");
                    return response
                }, async function (error) {
                    //console.log("[http.helper] ERROR >> ",error);
                    //console.log("[http.helper] httpPublicPOST :: API_GATEWAY_PATH 03 / error 01: ",error);
                    const originalRequest = error.config;
                    var errorData = error.response.data;
                    var errorMessage = errorData.message;
                    //console.log("[http.helper] httpPublicPOST :: API_GATEWAY_PATH 03 / error 02: ",error.response.status);

                    if (error.response.status !== 401) {
                        // Cuando es ERROR de BACKEND (500)
                        if(error.response.status == 500){
                            var objError = new HTTPErrorHelper('BACKEND', errorMessage);
                            return Promise.reject(objError);
                        }

                        // Cuando es cualquier otro ERROR (!=401)
                        var objError = new HTTPErrorHelper('GENERAL', error);
                        return new Promise((resolve, reject) => {
                            reject(objError);
                        });
                    }

                    if (error.response.status === 401 && !originalRequest._retry) {
                        //console.log("[http.helper] httpPublicPOST :: API_GATEWAY_PATH 03 / error.response.status === 401 (1) ");
                        originalRequest._retry = true;

                        var ENV_AUTHPATH = process.env.API_AUTH_PATH;
                        var urlRequest = ENV_AUTHPATH+"/token/personal";

                        if(isDebug){
                            urlRequest = urlRequest+"?XDEBUG_SESSION_START=phpstorm-xdebug";
                        }

                        //var encryptedEmail = Buffer.from(process.env.API_TOKEN_PUBLIC_USER).toString('base64');
                        //var encryptedPassword = Buffer.from(process.env.API_TOKEN_PUBLIC_PASSWORD).toString('base64');
                        var encryptedEmail = btoa(process.env.API_TOKEN_PUBLIC_USER);
                        var encryptedPassword = btoa(process.env.API_TOKEN_PUBLIC_PASSWORD);

                        var objRequest = {
                            email: encryptedEmail,
                            clave: encryptedPassword,
                        }

                        //console.log("[http.helper] httpPublicPOST :: API_GATEWAY_PATH 03 / error.response.status === 401 (2) ");

                        var result = await axios.post(urlRequest, objRequest);
                        var responseStatus = result.status;
                        var responseMessage = result.data.message;
                        var responseData = result.data.response;
                        //console.log("[http.helper] httpPublicPOST :: API_GATEWAY_PATH 03 / error.response.status === 401 (3) / axios.post: ",result);

                        switch(responseStatus) {
                            case 200:
                                //console.log("[http.helper] httpPublicPOST :: API_GATEWAY_PATH 03 / error.response.status === 401 (3) / responseStatus==200 (1)");

                                // 1. Se obtuvo CON ÉXITO el AccessToken
                                var newPublicAccessToken = responseData.access_token;
                                //console.log("[http.helper] httpPublicPOST :: API_GATEWAY_PATH 03 / error.response.status === 401 (3) / responseStatus==200 (2)");

                                // 2. Almacenamos el NUEVO AccessToken:
                                //store.dispatch('authStore/setAccessToken',newPublicAccessToken);
                                AuthHelper.setAccessToken(newPublicAccessToken);
                                //console.log(">>> NEW publicAccessToken = "+newPublicAccessToken);
                                //console.log("[http.helper] httpPublicPOST :: API_GATEWAY_PATH 03 / error.response.status === 401 (3) / responseStatus==200 (3)");

                                // 3. Con el nuevo AccessToken válido realizamos de nuevo la llamada al API
                                axios.defaults.headers.common['Authorization'] = 'Bearer ' + newPublicAccessToken;
                                //console.log("[http.helper] httpPublicPOST :: API_GATEWAY_PATH 03 / error.response.status === 401 (3) / responseStatus==200 (4)");

                                return axiosApiInstance(originalRequest);
                                break;

                            default:
                                //console.log("[http.helper] httpPublicPOST :: API_GATEWAY_PATH 03 / error.response.status === 401 (3) / responseStatus==error (1)");
                                var objError = new HTTPErrorHelper('TOKEN', 'Error en Personal Token - '+responseMessage);
                                //console.log("[http.helper] httpPublicPOST :: API_GATEWAY_PATH 03 / error.response.status === 401 (3) / responseStatus==error (2)");
                                return Promise.reject(objError);
                        }
                    }

                    var objError = new HTTPErrorHelper('GENERAL', errorMessage);
                    return Promise.reject(objError);

                }
            );

            //console.log("[http.helper] httpPublicPOST -------------------------------");
            //console.log("[http.helper] httpPublicPOST :: API_GATEWAY_PATH 03 - POST 1");
            if(isBlob){
                return axiosApiInstance({
                    url: url,
                    data: body,
                    method: 'POST',
                    responseType: 'blob',
                });
            }
            //console.log("[http.helper] httpPublicPOST :: API_GATEWAY_PATH 03 - POST 2 // url: ",url," / body: ",body);
            var postResult = await axiosApiInstance.post(url,body);
            //console.log("[http.helper] httpPublicPOST :: API_GATEWAY_PATH 03 - POST 3 // postResult: ",postResult);
            return postResult;

        } catch (error) {
            var objError = new HTTPErrorHelper('GENERAL', error);
            return Promise.reject(objError);
        }
    }

    async httpSessionPOST(url,body,isDebug=false,isBlob=false){
        try {
            // [IMPORTANT]
            // You cannot refresh a Refresh Token if the Refresh Token has expired or otherwise been revoked.
            // You must repeat the authentication flow to obtain a new Refresh Token.

            //var actualSessionAccessToken = store.getters['authStore/getAccessToken'];
            //var actualSessionRefreshToken = store.getters['authStore/getRefreshToken'];
            var actualSessionAccessToken = AuthHelper.getAccessToken();
            var actualSessionRefreshToken = AuthHelper.getAccessToken();

            //console.log("[httpSessionPOST]  ACTUAL sessionAccessToken = ",actualSessionAccessToken);
            //console.log("[httpSessionPOST]  ACTUAL sessionRefreshToken = ",actualSessionRefreshToken);

            if(actualSessionAccessToken=="" || actualSessionRefreshToken==""){
                // CASO: Uno de los tokens ha sido COMPROMETIDO.
                //       Hacemos LOGOUT para que el usuario haga LOGIN y se creen nuevos tokens
                var errorMessage = "LOGOUT porque uno de los TOKENS ha sido comprometido";
                //store.dispatch('authStore/logOut');
                AuthHelper.logOut();

                //var nextRoute = 'session_index';
                //router.replace({ name: nextRoute }, () => {
                //});

                var objError = new HTTPErrorHelper('LOGOUT', errorMessage);
                return Promise.reject(objError);
            }

            var httpURL = process.env.API_GATEWAY_PATH;

            if(isDebug){
                httpURL = httpURL+"?XDEBUG_SESSION_START=phpstorm-xdebug";
            }

            var objRequest = {};
            objRequest['url'] = url;
            objRequest['data'] = body;

            const axiosApiInstance = axios.create();

            axiosApiInstance.interceptors.request.use(
                config => {
                    config.url = httpURL;
                    config.data = objRequest;

                    //var accessToken = store.getters['authStore/getAccessToken'];
                    var accessToken = AuthHelper.getAccessToken();
                    //console.log(">>> accessToken = "+accessToken);
                    config.headers['Authorization'] = 'Bearer ' + accessToken;
                    return config;
                },
                error => {
                    var objError = new HTTPErrorHelper('TOKEN', error);
                    return Promise.reject(objError);
                }
            );

            axiosApiInstance.interceptors.response.use((response) => {
                return response
            }, async function (error) {
                const originalRequest = error.config;
                var errorData = error.response.data;
                var errorMessage = errorData.message;

                if (error.response.status !== 401) {
                    // Cuando es ERROR de BACKEND (500)
                    if(error.response.status == 500){
                        var objError = new HTTPErrorHelper('BACKEND', errorMessage);
                        return Promise.reject(objError);
                    }

                    // Cuando es cualquier otro ERROR (!=401)
                    var objError = new HTTPErrorHelper('GENERAL', error);
                    return new Promise((resolve, reject) => {
                        reject(objError);
                    });
                }

                if (error.response.status === 401 && !originalRequest._retry) {
                    originalRequest._retry = true;

                    var ENV_AUTHPATH = process.env.API_AUTH_PATH;
                    var urlRequest = ENV_AUTHPATH+"/token/refresh";

                    if(isDebug){
                        urlRequest = urlRequest+"?XDEBUG_SESSION_START=phpstorm-xdebug";
                    }

                    //var sessionRefreshToken = store.getters['authStore/getRefreshToken'];
                    var sessionRefreshToken = AuthHelper.getRefreshToken();

                    var objRequest = {
                        refreshToken: sessionRefreshToken,
                    }

                    var result = await axios.post(urlRequest, objRequest);
                    var responseStatus = result.status;
                    var responseMessage = result.data.message;
                    var responseData = result.data.response;

                    switch(responseStatus) {
                        case 200:
                            // 1. Se obtuvo CON ÉXITO el AccessToken/RefreshToken
                            var newSessionRefreshToken = responseData.refresh_token;
                            var newSessionAccessToken = responseData.access_token;

                            //console.log("[httpSessionPOST] NEW newSessionRefreshToken = ",newSessionRefreshToken);
                            //console.log("[httpSessionPOST] NEW sessionAccessToken = ",newSessionAccessToken);

                            // 2. Almacenamos el NUEVO AccessToken:
                            //store.dispatch('authStore/setRefreshToken',newSessionRefreshToken);
                            //store.dispatch('authStore/setAccessToken',newSessionAccessToken);
                            AuthHelper.setRefreshToken(newSessionRefreshToken);
                            AuthHelper.setAccessToken(newSessionAccessToken);

                            // 3. Con el nuevo AccessToken válido realizamos de nuevo la llamada al API
                            axios.defaults.headers.common['Authorization'] = 'Bearer ' + newSessionAccessToken;
                            return axiosApiInstance(originalRequest);
                            break;

                        default:
                            // CASO: Los tokens han EXPIRADO o ya NO SON VÁLIDOS.
                            //       Hacemos LOGOUT para que el usuario haga LOGIN y se creen nuevos tokens
                            var errorMessage = "LOGOUT por superar tiempo de inactividad";
                            //store.dispatch('authStore/logOut');
                            AuthHelper.logOut();

                            //var nextRoute = 'session_index';
                            //router.replace({ name: nextRoute }, () => {
                            //});

                            var objError = new HTTPErrorHelper('LOGOUT', errorMessage);
                            return Promise.reject(objError);
                    }
                }

                var objError = new HTTPErrorHelper('GENERAL', errorMessage);
                return Promise.reject(objError);

            });

            if(isBlob){
                return axiosApiInstance({
                    url: url,
                    data: body,
                    method: 'POST',
                    responseType: 'blob',
                });
            }

            return axiosApiInstance.post(url,body);

        } catch (error) {
            var objError = new HTTPErrorHelper('GENERAL', error);
            return Promise.reject(objError);
        }
    }

    async httpPOST(url,body,accessType='PUBLIC',isDebug=false){
        if(isDebug){
            url = url+"?XDEBUG_SESSION_START=phpstorm-xdebug";
        }
        //console.log("[http.helper] httpPOST ("+accessType+") // URL: "+url);

        switch(accessType) {
            case 'PUBLIC':
                return await this.httpPublicPOST(url,body,isDebug);
            case 'SESSION':
                return await this.httpSessionPOST(url,body,isDebug);
            default:
                var objError = new HTTPErrorHelper('GENERAL', "Invalid ACCESSTYPE = '"+accessType+"'");
                return Promise.reject(objError);
        }
    }

    async httpPOSTBlob(url,body,accessType='PUBLIC',isDebug=false){
        if(isDebug){
            url = url+"?XDEBUG_SESSION_START=phpstorm-xdebug";
        }

        switch(accessType) {
            case 'PUBLIC':
                return await this.httpPublicPOST(url,body,isDebug,true);

            case 'SESSION':
                return await this.httpSessionPOST(url,body,isDebug,true);

            default:
                var objError = new HTTPErrorHelper('GENERAL', "Invalid ACCESSTYPE = '"+accessType+"'");
                return Promise.reject(objError);
        }
    }
}

const instance = HTTPHelper.getInstance();
export default instance;