import SecureLS from "secure-ls";

class StorageHelper {
  static instance = null;
  static createInstance() {
    var object = new StorageHelper();
    return object;
  }

  static getInstance() {
    if (!StorageHelper.instance) {
      StorageHelper.instance = StorageHelper.createInstance();
    }
    return StorageHelper.instance;
  }

  constructor() {
    this.ls = new SecureLS({ encodingType: "aes", isCompression: true });
  }

  setItem(key, value) {
    try {
      if (value != null) {
        this.ls.set(key, JSON.stringify(value));
      } else {
        this.ls.set(key, "");
      }
    } catch (error) {
      this.ls.set(key, "");
    }
  }

  getItem(key) {
    try {
      var value = this.ls.get(key);

      if (value == null || value == "") {
        return "";
      }

      return JSON.parse(value);
    } catch (error) {
      return "";
    }
  }

  removeItem(key) {
    this.ls.remove(key);
  }

  clear() {
    this.ls.clear();
  }
}

const instance = StorageHelper.getInstance();
export default instance;
