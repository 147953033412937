export default class EventHelper {
  static isAuthenticated(isAuthenticated = false) {
    var showLoadingEventConfig = {};
    showLoadingEventConfig["isAuthenticated"] = isAuthenticated;

    var customEventDetail = {};
    customEventDetail["detail"] = showLoadingEventConfig;

    const event = new CustomEvent("isAuthenticatedEvent", customEventDetail);

    window.dispatchEvent(event);
  }

  static isRootLogoVisible(isVisible = true) {
    var showLoadingEventConfig = {};
    showLoadingEventConfig["isVisible"] = isVisible;

    var customEventDetail = {};
    customEventDetail["detail"] = showLoadingEventConfig;

    const event = new CustomEvent("isRootLogoVisibleEvent", customEventDetail);

    window.dispatchEvent(event);
  }

  static scrollToDiv(divName = "") {
    var showLoadingEventConfig = {};
    showLoadingEventConfig["divName"] = divName;

    var customEventDetail = {};
    customEventDetail["detail"] = showLoadingEventConfig;

    const event = new CustomEvent("scrollToDivEvent", customEventDetail);

    window.dispatchEvent(event);
  }

  static showInitialLoading(apiType = "PUBLIC", transition = "LEFT") {
    var showLoadingEventConfig = {};
    showLoadingEventConfig["isVisible"] = true;
    showLoadingEventConfig["apiType"] = apiType;
    showLoadingEventConfig["text"] = "";
    showLoadingEventConfig["transition"] = transition;

    var customEventDetail = {};
    customEventDetail["detail"] = showLoadingEventConfig;

    const event = new CustomEvent("showLoadingEvent", customEventDetail);

    window.dispatchEvent(event);
  }

  static showModal(
      isVisible,
      id,
      type = "ALERT",
      apiType = "PUBLIC",
      text = "",
      transition = ""
  ) {
    var showModalEventConfig = {};
    showModalEventConfig["isVisible"] = isVisible;
    showModalEventConfig["id"] = id;
    showModalEventConfig["type"] = type;
    showModalEventConfig["apiType"] = apiType;
    showModalEventConfig["text"] = text;
    showModalEventConfig["transition"] = transition;

    var customEventDetail = {};
    customEventDetail["detail"] = showModalEventConfig;

    const event = new CustomEvent("showModalEvent", customEventDetail);

    window.dispatchEvent(event);
  }

  static responseModal(
      id,
      type = "ACCEPT",
  ) {
    var responseModalEventConfig = {};
    responseModalEventConfig["id"] = id;
    responseModalEventConfig["type"] = type;

    var customEventDetail = {};
    customEventDetail["detail"] = responseModalEventConfig;

    const event = new CustomEvent("responseModalEvent", customEventDetail);

    window.dispatchEvent(event);
  }

  static showLoading(
    isVisible,
    apiType = "PUBLIC",
    text = "",
    transition = "LEFT"
  ) {
    //console.log("[EventHelper] showLoading :: isVisible",isVisible);
    //console.log("[EventHelper] showLoading :: routeAccessType",routeAccessType);
    //console.log("[EventHelper] showLoading :: text",text);
    //console.log("[EventHelper] showLoading :: transition",transition);

    var showLoadingEventConfig = {};
    showLoadingEventConfig["isVisible"] = isVisible;
    showLoadingEventConfig["apiType"] = apiType;
    showLoadingEventConfig["text"] = text;
    showLoadingEventConfig["transition"] = transition;

    var customEventDetail = {};
    customEventDetail["detail"] = showLoadingEventConfig;

    const event = new CustomEvent("showLoadingEvent", customEventDetail);

    window.dispatchEvent(event);
  }

  static showError(
      isVisible,
      text = ""
  ) {
    //console.log("[EventHelper] showError :: isVisible",isVisible);
    //console.log("[EventHelper] showError :: text",text);

    var showErrorEventConfig = {};
    showErrorEventConfig["isVisible"] = isVisible;
    showErrorEventConfig["text"] = text;

    var customEventDetail = {};
    customEventDetail["detail"] = showErrorEventConfig;

    const showErrorEvent = new CustomEvent("showErrorEvent", customEventDetail);

    window.dispatchEvent(showErrorEvent);

    // Cerramos el LoadingPage
    var showLoadingEventConfig = {};
    showLoadingEventConfig["isVisible"] = false;

    customEventDetail = {};
    customEventDetail["detail"] = showLoadingEventConfig;

    const showLoadingEvent = new CustomEvent("showLoadingEvent", customEventDetail);

    window.dispatchEvent(showLoadingEvent);
  }


  static setLayout(layout = "PUBLIC") {
    var showLoadingEventConfig = {};
    showLoadingEventConfig["layout"] = layout;

    var customEventDetail = {};
    customEventDetail["detail"] = showLoadingEventConfig;

    const event = new CustomEvent("setLayoutEvent", customEventDetail);

    window.dispatchEvent(event);
  }

  // VUEX: STORE
  static vuexStore(
      type,
      namespace,
      data = null
  ) {
    // =====================
    // type: 'GET','SET', 'DELETE'
    // namespace: String
    // data: Object
    // =====================

    var vuexStoreEventConfig = {};
    vuexStoreEventConfig["type"] = type;
    vuexStoreEventConfig["namespace"] = namespace;
    vuexStoreEventConfig["data"] = data;

    var customEventDetail = {};
    customEventDetail["detail"] = vuexStoreEventConfig;

    const event = new CustomEvent("vuexStoreEvent", customEventDetail);

    window.dispatchEvent(event);
  }
}
