export default class MediaQueriesHelper {

  static gridLoopStyle(loopLength, loopIndex, objGridCols, objGridStyle=null) {
    const matchMediaSmall = window.matchMedia( "(max-width: 39.9375em)" );
    const matchMediaMedium = window.matchMedia( "(min-width: 40em) and (max-width: 63.9375em)" );
    const matchMediaLarge = window.matchMedia( "(min-width: 64em) and (max-width: 74.9375em)" );
    const matchMediaXLarge = window.matchMedia( "(min-width: 75em)" );

    var cols = 1;

    if (matchMediaSmall.matches) {
      cols = objGridCols['small'];
    }

    if (matchMediaMedium.matches) {
      cols = objGridCols['medium'];
    }

    if (matchMediaLarge.matches) {
      cols = objGridCols['large'];
    }

    if (matchMediaXLarge.matches) {
      cols = objGridCols['xlarge'];
    }

    if(objGridStyle==null){
      objGridStyle = {};
      objGridStyle['padding-left'] = '10px';
      objGridStyle['padding-right'] = '10px';
      objGridStyle['padding-top'] = 0;
      objGridStyle['padding-bottom'] = '20px';
    }

    var actualIndex = loopIndex % cols;
    var nextIndex = (loopIndex + 1) % cols;

    var objStyle = {};
    objStyle['width'] = '100%';

    if(cols>1){
      if(nextIndex==0) {
        objStyle['padding-left'] = objGridStyle['padding-left'];
        objStyle['padding-right'] = 0;
        objStyle['max-width'] = "calc("+(100/cols)+"% - 10px)";
      } else {
        if(actualIndex==0) {
          objStyle['padding-left'] = 0;
          objStyle['padding-right'] = objGridStyle['padding-right'];
          objStyle['max-width'] = "calc("+(100/cols)+"% - 10px)";
        } else {
          objStyle['padding-left'] = objGridStyle['padding-left'];
          objStyle['padding-right'] = objGridStyle['padding-right'];
          objStyle['max-width'] = "calc("+(100/cols)+"% - 20px)";
        }
      }
    } else {
      objStyle['padding-left'] = 0;
      objStyle['padding-right'] = 0;
      objStyle['max-width'] = "calc("+(100/cols)+"%)";
    }

    var rows = Math.ceil(loopLength/cols);
    var indexLastRow = (cols * rows)-1;

    if(loopLength>cols){
      if(indexLastRow<0){
        if(loopIndex<(loopLength-1)){
          objStyle['padding-bottom'] = objGridStyle['padding-bottom'];
        }
      } else {
        if(loopIndex<indexLastRow){
          objStyle['padding-bottom'] = objGridStyle['padding-bottom'];
        }
      }
    }

    return objStyle;
  }

  static getResponsiveRange() {
    const matchMediaSmall = window.matchMedia( "(max-width: 39.9375em)" );
    const matchMediaMedium = window.matchMedia( "(min-width: 40em) and (max-width: 63.9375em)" );
    const matchMediaLarge = window.matchMedia( "(min-width: 64em) and (max-width: 74.9375em)" );
    const matchMediaXLarge = window.matchMedia( "(min-width: 75em)" );

    if (matchMediaSmall.matches) {
      return 'small';
    }

    if (matchMediaMedium.matches) {
      return 'medium';
    }

    if (matchMediaLarge.matches) {
      return 'large';
    }

    if (matchMediaXLarge.matches) {
      return 'xlarge';
    }

    return 'xlarge';
  }

  static isDesktopView() {
    const matchMedia = window.matchMedia( "(min-width: 64em)" );

    if (matchMedia.matches) {
      return true;
    }

    return false;
  }

  static isMobileView() {
    const matchMediaSmall = window.matchMedia( "(max-width: 39.9375em)" );
    const matchMediaMedium = window.matchMedia( "(min-width: 40em) and (max-width: 63.9375em)" );

    var cont = 0;
    if (matchMediaSmall.matches) {
      cont = cont + 1;
    }

    if (matchMediaMedium.matches) {
      cont = cont + 1;
    }

    if(cont>0){
      return true;
    }

    return false;
  }

}
