export default class ImageHelper {
  static countImages(idPage) {
    var contHidden = 0;
    var contVisible = 0;
    var pageChilds = document.getElementById(idPage).childNodes;

    for (var i=0;i<pageChilds.length;i++) {
      var style = window.getComputedStyle(pageChilds[i]);
      if(((style.display === 'none') || (style.visibility === 'hidden'))){
        // HIDDEN CONTENT
        //console.log("HIDDEN: ", pageChilds[i].className," / CONT: ",pageChilds[i].getElementsByClassName('pixellar_image').length);
        contHidden = contHidden + pageChilds[i].getElementsByClassName('pixellar_image').length;
      } else {
        // VISIBLE CONTENT
        //console.log("VISIBLE: ", pageChilds[i].className," / CONT: ",pageChilds[i].getElementsByClassName('pixellar_image').length);
        contVisible = contVisible + pageChilds[i].getElementsByClassName('pixellar_image').length;
      }
    }

    var result = {};
    result['hidden'] = contHidden;
    result['visible'] = contVisible;

    return result;
  }

}
