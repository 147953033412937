import StorageHelper from "./storage.helper";

export default class AuthHelper {
  // LOG IN - LOG OUT
  static getAuthenticated() {
    var authenticated = StorageHelper.getItem("sigeda_faro_auth_authenticated") || null;
    if (authenticated == null) {
      return false;
    }
    return authenticated;
  }
  static logIn() {
    StorageHelper.setItem('sigeda_faro_auth_authenticated', true);
  }
  static logOut() {
    StorageHelper.removeItem('sigeda_faro_auth_authenticated');
    StorageHelper.setItem('sigeda_faro_auth_authenticated', null);

    StorageHelper.removeItem('sigeda_faro_auth_user');
    StorageHelper.setItem('sigeda_faro_auth_user', null);
  }

  // USER
  static getUser(state) {
    var user = StorageHelper.getItem('sigeda_faro_auth_user') || null;
    return user;
  }
  static setUser(data) {
    StorageHelper.setItem('sigeda_faro_auth_user', data);
  }
  static deleteUser() {
    StorageHelper.removeItem('sigeda_faro_auth_user');
  }

  // ACCESS TOKEN
  static getAccessToken() {
    var accessToken = StorageHelper.getItem('sigeda_faro_auth_accessToken') || null;
    if (accessToken == null) {
      return "";
    }
    return accessToken;
  }
  static setAccessToken(data) {
    StorageHelper.setItem('sigeda_faro_auth_accessToken', data);
  }
  static deleteAccessToken() {
    StorageHelper.removeItem('sigeda_faro_auth_accessToken');
  }

  // REFRESH TOKEN
  static getRefreshToken() {
    var refreshToken= StorageHelper.getItem('sigeda_faro_auth_refreshToken') || null;
    if (refreshToken == null) {
      return "";
    }
    return refreshToken;
  }
  static setRefreshToken(data) {
    StorageHelper.setItem('sigeda_faro_auth_refreshToken', data);
  }
  static deleteRefreshToken() {
    StorageHelper.removeItem('sigeda_faro_auth_refreshToken');
  }
}
